<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Operarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Operarios</option>
                    <option :value="2">Documentos</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Tipo de Documento</label>
                <select
                  id="tipo_documento"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.tipo_documento"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_documento in listasForms.tipo_documentos"
                    :key="tipo_documento.numeracion"
                    :value="tipo_documento.numeracion"
                  >
                    {{ tipo_documento.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Numero de Documento</label>
                <input
                  type="number"
                  placeholder="Numero de Documento"
                  id="numero_documento"
                  v-model="filtros.numero_documento"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Nombres</label>
                <input
                  type="text"
                  id="nombres"
                  placeholder="Nombres"
                  v-model="filtros.nombres"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Apellidos</label>
                <input
                  type="text"
                  id="apellidos"
                  placeholder="Apellidos"
                  label="apellidos"
                  v-model="filtros.apellidos"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Email</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  label="email"
                  v-model="filtros.email"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Localidad</label>
                <select
                  id="localidad"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.localidad"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="localidad in listasForms.localidades"
                    :key="localidad.numeracion"
                    :value="localidad.numeracion"
                  >
                    {{ localidad.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Tipo de Sangre</label>
                <select
                  id="tipo_sangre"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.tipo_sangre"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_sangre in listasForms.tipo_sangres"
                    :key="tipo_sangre.numeracion"
                    :value="tipo_sangre.numeracion"
                  >
                    {{ tipo_sangre.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Estado</label>
                <select
                  id="tipo_operacion"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.estado"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Empresa</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.empresas"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="empresa in listasForms.empresas"
                    :key="empresa.id"
                    :value="empresa.id"
                  >
                    {{ empresa.razon_social }}
                  </option>
                </select>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="$store.getters.can('admin.operarios.pivotTable')"
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "OperarioPivotTable",
  components: {
    PivotTableComponent,
    Loading,
  },
  data() {
    return {
      cargando: false,
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      filtros: {
        tipo_documento: null,
        numero_documento: null,
        nombres: null,
        apellidos: null,
        email: null,
        localidad: null,
        tipo_sangre: null,
        estado: null,
        empresas: null,
      },
      tipo_consulta: null,
      listasForms: {
        tipo_documentos: [],
        localidades: [],
        empresas: [],
        categoria_licencias: [],
        tipo_sangres: [],
        estados: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Admin/Operarios");
    },
    getTipoDocumentos() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    getLocalidades() {
      axios.get("/api/lista/22").then((response) => {
        this.listasForms.localidades = response.data;
      });
    },

    getCategoriaLicencias() {
      axios.get("/api/lista/17").then((response) => {
        this.listasForms.categoria_licencias = response.data;
      });
    },
    getTipoSangres() {
      axios.get("/api/lista/16").then((response) => {
        this.listasForms.tipo_sangres = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/operarios/pivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    await this.getTipoDocumentos();
    await this.getLocalidades();
    await this.getCategoriaLicencias();
    await this.getTipoSangres();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
